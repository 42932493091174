(function ($) {
    //Function header fixo
    function scrollHeader(){
        var sectHeight =  $('.main-sect').height();
        $(window).on("scroll", function() {
            if($(this).scrollTop() >= (sectHeight-100)){

                $('html').addClass('with-fixed-header');
                $('header').addClass('fixed-header');
            }else {
                $('html').removeClass('with-fixed-header');
                $('header').removeClass('fixed-header');
            }
        });
    }

    //Function que checa se o usuário acessou no mobile
    function isMobile(){
        var ua = navigator.userAgent.toLowerCase(),
            platform = navigator.platform.toLowerCase();
        platformName = ua.match(/ip(?:ad|od|hone)/) ? 'ios' : (ua.match(/(?:webos|android)/) || platform.match(/mac|win|linux/) || ['other'])[0],
            mobile = /ios|android|webos/.test(platformName);
        return mobile;
    }

    //Function Linha
    function animatedLine (target){
        $(window).on("scroll", function() {
            if ($(window).scrollTop() >= $(target).offset().top - 100) {
                $(target).addClass('loaded');
            } else {
                $(target).removeClass('loaded');
            }
        });
    }

    //Function que Inicia o Data Table
    //Parametro para a quantidade de Registros que sera exibida
    if(isMobile()){
        nCols = 4;
    }
    else{
        nCols = 12;
    }

    $('#portifolio-gallery').lightGallery({
        thumbnail: true,
        showThumbByDefault: true,
        selector: '.lightgallery_item'
    });


    $('#gallery-wrap').on('click', '.filter', function (e) {
        var $this = $(this),
            eventId = $this.data('cat'),
            $galleryList = $('#portifolio-gallery'),
            galleryWrap = $('#gallery-wrap'),
            html = "";

        galleryWrap.addClass('loading');
        
        $.get('/api/getEvents/' + eventId)

            .done(function (data) {

                if(data.length){
                    for(var $c=0; $c < data.length; $c++){
                        if($c == 0){
                            html += '<div class="col-left">';
                        }

                        if($c == 3) {
                            html+= '<div class="col-center">';
                        }

                        if($c == 6){
                            html += '<div class="col-right">';
                        }

                        var _feat = $c == 5 ? " item-destaque " : "";

                        var hide = $c > 8 ? 'hide' : '';

                        html += '<div class="item-galeria ' + hide +' fadeInUp' + _feat + '"  data-delay="1000">';
                        html += '<a class="lightgallery_item" data-src="' + data[$c].featured_image_url + '" data-sub-html="<p>' + data[$c].name +'</p>">';
                        html += '<img src="' + data[$c].featured_image_url + '" alt="'+ data[$c].name + '">';
                        html += '<div class="item-description"><div class="title">'+ data[$c].name +'</div>';
                        html += '<p class="location">' + data[$c].place +'</p>';
                        html += '<p>' + data[$c].cityandstate + '</p>';
                        html += '</div></a></div>';

                        // Se for a última iteração e ainda da primeira coluna ou se for o fim da iteração da primeira coluna
                        if(($c == data.length - 1 && $c <= 2 || $c == 2)){
                            html += '</div>';
                        }


                        // Se for a ultima iteração da segunda coluna ou se for o fim da iteração da segunda coluna --}}
                        if(($c == data.length - 1 && $c <= 5 && $c > 2 || $c == 5)){
                            html += '</div>';
                        }

                        // Se for a ultima iteração da terceira coluna ou se for o fim da iteração da terceira coluna --}}
                        if(($c == data.length - 1 && $c <= 8 && $c > 5 || $c == 8)){
                            html += '</div>';
                        }


                        $galleryList.html('');
                        $galleryList.append(html);

                        galleryWrap.removeClass('loading');

                        var _items = $galleryList.find('.item-galeria');

                        // Animate
                        _items.each(function (key, elem) {
                            $(elem).addClass('animated');
                        });

                        $galleryList.data('lightGallery').destroy(true);

                        $galleryList.lightGallery({
                            thumbnail: true,
                            showThumbByDefault: true,
                            selector: '.lightgallery_item'
                        });
                    }
                }

                else {
                    $galleryList.html('');
                    $galleryList.append('<p>Nenhum evento cadastrado.</p>');

                    // Loading
                    galleryWrap.removeClass('loading');

                }
            })

            .fail(function (error) {
                console.log(error);

                galleryWrap.removeClass('loading')
            });

    });

    //Doc Ready
    $(function(){
        if(!$('body').hasClass('interna')) {
            scrollHeader(),
                animatedLine('.depoimentos-wrapper');
        }
        else{
            if($('body').hasClass('contato')){
                return;
            }

            if($('body').hasClass('eventos')){
                animatedLine('.depoimentos-wrapper');
            }

        }

        $(window).scroll(function () {
            scrollHeader();
        });

        //Carousel Sincronizado de Instrumentos Musicais
        var sync1 = $('#backgroundCarousel'),
            sync2 = $('#instrumentosCarousel'),
            duration = 300,
            thumbs = 4;
        // Sync nav
        sync2.on('click', '.owl-next', function () {
            sync1.trigger('next.owl.carousel')
        });
        sync2.on('click', '.owl-prev', function () {
            sync1.trigger('prev.owl.carousel')
        });

        // Start Carousel
        sync1.owlCarousel({
                center : false,
                loop: true,
                mouseDrag: false,
                items : 1,
                margin:0,
                nav : true,
                animateOut: 'fadeOut'
            })
            .on('dragged.owl.carousel', function (e) {
                if (e.relatedTarget._drag.direction == 'left') {
                    sync2.trigger('next.owl.carousel')
                } else {
                    sync2.trigger('prev.owl.carousel')
                }
            });

        sync2.owlCarousel({
                loop:true,
                margin:10,
                mouseDrag: false,
                nav:true,
                center: true,
                navText: ['<i class="fa fa-angle-left">', '<i class="fa fa-angle-right">'],
                responsive:{
                    0:{
                        items:1
                    },
                    600:{
                        items:3
                    },
                    1000:{
                        items:3,
                        nav: true
                    }
                }
            }).on('click', '.owl-item', function() {
                var i = $(this).index()-(thumbs+1);
                sync2.trigger('to.owl.carousel', [i, duration, true]);
                sync1.trigger('to.owl.carousel', [i, duration, true]);
            });

        //Carousel Estilos Musicais
        $('#estilosCarousel').owlCarousel({
            loop:false,
            margin:30,
            nav:false,
            center: false,
            responsive:{
                0:{
                    items:1,
                    dots: true,
                    mouseDrag: false,
                    loop: true
                },
                600:{
                    items:3
                },
                1000:{
                    items:3
                }
            }
        });

        //Carousel de Tipos de Eventos só inicializado no mobile
        if(isMobile()){
            $('#eventsCarousel').addClass('owl-carousel');

            $('#eventsCarousel').owlCarousel({
                loop:true,
                margin:0,
                items: '.item-wrapper',
                nav:false,
                center: false,
                responsive:{
                    0:{
                        items:1,
                        dots: true,
                        mouseDrag: false
                    },
                    768:{
                        items:2
                    }
                }
            });
        }

        //Carousel para o logo dos clientes
        $('#clientesCarousel').owlCarousel({
            loop:true,
            margin:30,
            nav:false,
            center: false,
            autoplay: true,
            responsive:{
                0:{
                    items:2,
                    margin: 10,
                    autoplay: true,
                    autoplayTimeout: 2000,
                    loop: true
                },
                600:{
                    items:4
                },
                1000:{
                    items:4
                }
            }
        });

        //Show form das paginas internas
        $('#btnInterna').click(function () {
            if($('.content-sect').hasClass('show-form')){
                $('.content-sect').removeClass('show-form');
            } else {
                $('.content-sect').addClass('show-form');
            }
        });

        //Function Nav Portifolio
        $('.portifolio-nav li a').click(function(event){
            event.preventDefault();
            if($(this).hasClass('active')){
                return;
            }else{
                $('.portifolio-nav li a.active').removeClass('active');
                $(this).addClass('active');
            }
        });

        //Navegação do Blog
        $('.blog-items li').click(function () {

            $('.current-img').attr('src', $(this).data('featured'));

            if($(this).hasClass('active')){
                return;
            } else{
                $('.blog-items li.active').removeClass('active');

                $(this).addClass('active');
            }
        });

        //Mostra campos ocultos dos formularios da pagina interna de eventos
        $('.checkbox-button').bind('change', function () {
            var target = $(this).data('input');
            if ($(this).is(':checked'))
                $('#'+target).addClass('show-input');
            else
                $('#'+target).removeClass('show-input');

        });

        $('#location-select').on('change', function (e) {
            var val = $(this).val(),
                $this = $(this);

            if(val == 'outro') {
                $this.addClass('hide');
                $('#location-other').removeClass('hide');
            }
            else {
                $this.removeClass('hide');
                $('#location-other').addClass('hide');
            }
        });

        //Player
        $('.player').click(function(){
            $('html').addClass('show-playlist');
        });

        $('.hide-player').click(function(){
            $('html').removeClass('show-playlist');
        });

        // Mount Playlist
        $.ajax({
            type: 'GET',
            dataType: 'json',
            url: '/api/getMusics?withFile=yes',

            success: function (data) {
                new jPlayerPlaylist({
                        jPlayer: "#jquery_jplayer_N",
                        cssSelectorAncestor: "#jp_container_N"
                    }, data,
                    {
                        playlistOptions: {
                            enableRemoveControls: false,
                            autoPlay: false
                        },
                        swfPath: "/js",
                        supplied: "ogv, m4v, oga, mp3",
                        smoothPlayBar: true,
                        keyEnabled: true,
                        audioFullScreen: true // Allows the audio poster to go full screen via keyboard
                    });
            }
        });

        // Mobile Dropdown
        $('.mobile-nav .dropdown').click(function (e) {

            if(e.target == this){

                var forClose = $(this).hasClass('open');

                $('.dropdown').removeClass('open');

                if(!forClose){
                   $(this).addClass('open');
               }
            }

            else {
                e.preventDefault();

                window.location = e.target.href;
            }
        });


        // Button close form mobile
        $('.close-btn').click(function(){
           $('.content-sect').removeClass('show-form');
        });

        var parceiroDataTable = $('#parceiros_data_table'),
            repertorioDataTable = $('#repertorio_data_table');

        if(parceiroDataTable.length)
            $(parceiroDataTable).DataTable({
                responsive: true,
                info: false,
                lengthChange: false,
                ordering: true,
                pageLength: nCols,
                autoWidth: true,
                dom: '<f><t><ip>',
                language: {
                    "decimal":        "",
                    "emptyTable":     "Nenhum dado cadastrado",
                    "infoPostFix":    "",
                    "thousands":      ",",
                    "loadingRecords": "Carregando as Informações",
                    "processing":     "Buscando, aguarde um momento.",
                    "search":         "",
                    "zeroRecords":    "Nenhum registro encontrado",
                    "paginate": {
                        "next":       ">",
                        "previous":   "<"
                    }
                },
                processing: true,
                ajax: '/api/getParceiros?partner_id=' + partnerType,
                columns:  [
                    { data: "name", className: "title" },
                    { data: "address"},
                    { data: "city"},
                    { data: "tel"},
                    { data: "email"}
                ],

                // Usado somente em parceiros
                columnDefs: [
                    {
                        //Coloca o Link no campo de email
                        "render": function ( data ) {
                            var $href = "<a href='mailto:"+data+"'>"+data+"</a>";
                            return $("<div/>").append($href).html();
                        },
                        "targets": 4
                    }
                ]
            });

        if(repertorioDataTable.length)
            repertorioDataTable.DataTable({
                responsive: true,
                info: false,
                lengthChange: false,
                ordering: true,
                pageLength: nCols,
                autoWidth: true,
                dom: '<f><t><ip>',
                language: {
                    "decimal":        "",
                    "emptyTable":     "Nenhum dado cadastrado",
                    "infoPostFix":    "",
                    "thousands":      ",",
                    "loadingRecords": "Carregando as Informações",
                    "processing":     "Buscando, aguarde um momento.",
                    "search":         "",
                    "zeroRecords":    "Nenhum registro encontrado",
                    "paginate": {
                        "next":       ">",
                        "previous":   "<"
                    }
                },
                processing: true,
                ajax: '/api/getRepertorioBySlug?slug=' + repertoireSlug,
                columns:  [
                    { data: "name", className: "title" },
                    //{ data: "owner_name"},
                    { data: "album_name"}
                ]
            });
    });

})(jQuery);

function doSomething() {
    //Some code
    return false;
}
