$(function () {

    var $fakeGalleryContainer = $('.fake-gallery-container'),
        $playList = $fakeGalleryContainer.find('.fake-gallery-playlist'),
        $player = $fakeGalleryContainer.find('.fake-gallery-player'),
        $loader = $fakeGalleryContainer.find('.loader'),
        $closePlayerButton = $player.find('.fake-gallery-player-close');


    // Play Action
    $(document).on('click', '.fake-gallery-container .fake-gallery-video', function (e) {
        var _videoId = $(this).data('video-id'),
            template = 'https://www.youtube.com/embed/'+ _videoId;

        setTimeout(function () {
            $player.find('.fake-gallery-frameset').attr('src', template);
        }, 1000);


        $fakeGalleryContainer.addClass('playing');
    });

    // Close Player Action
    $closePlayerButton.on('click', function (e) {
        $fakeGalleryContainer.removeClass('playing');

        $player.find('.fake-gallery-frameset').attr('src', '');
    });

    // Dispatch
    $('.enable-gallery').on('click', function(e){
        e.preventDefault();

        // Tells to html that is running
        $('html').addClass('fake-gallery-running');

        $('.fake-gallery-container').addClass('show');


        if($loader.hasClass('loaded'))
            return false;

        $loader.addClass('loading');

        // Get Data
        $.get('https://www.googleapis.com/youtube/v3/search?order=date&part=snippet&channelId=UCdxRw4tQpRykBu0tEQk8xbg&maxResults=25&key=AIzaSyBuTfkFjzRIEcHB8wDw7o1seSHAaZ3sJnI')
            .done(function (result) {

                $loader.removeClass('loading');
                $fakeGalleryContainer.addClass('loaded');

                // If data is invalid
                if(result == null)
                    alert('Ocorreu um problema ao abrir a galeria.');

                // Define templates
                var source   = $("#fake-video-template").html();
                var template = Handlebars.compile(source);

                // Repeat
                for(var i in result.items){

                    var _context = {
                        videoId: result.items[i].id.videoId,
                        thumbnail: result.items[i].snippet.thumbnails.high.url,
                        videoName: result.items[i].snippet.title
                    };

                    var html    = template(_context);

                    // Put
                    $playList.append(html);
                }


            })
            .fail(function (e) {
                //
            });

    });

    //
    $fakeGalleryContainer.on('click', function (e) {

        if(e.target == this){
            $(this).removeClass('show');
            $('html').removeClass('fake-gallery-running');

            $(this).removeClass('playing');
            $player.find('.fake-gallery-frameset').attr('src', '');
        }

    });
});