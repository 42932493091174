$(function () {
    jQuery.validator.addMethod("testEmail", function (value, element) {
        return this.optional(element) || /[-0-9a-zA-Z.+_]+@[-0-9a-zA-Z.+_]+\.[a-zA-Z]{2,4}/i.test(value);
    }, "Digite e-mail valido.");

    var SPMaskBehavior = function (val) {
            return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
        },
        spOptions = {
            onKeyPress: function (val, e, field, options) {
                field.mask(SPMaskBehavior.apply({}, arguments), options);
            }
        };

    $('.celular-input').mask(SPMaskBehavior, spOptions);

    // Validação de Formularios
    $('#formHome').validate({
        rules: {
            nome: {
                required: true,
                minlength: 2
            },
            email: {
                required: true,
                email: true,
                testEmail: true
            },
            telefone: {
                required: true,
                minlength: 14
            },
            tipo: {
                required: true
            },
            mensagem: {
                required: true,
                minlength: 14
            }
        }, messages: {
            nome: {
                required: "Digite seu nome.",
                minlength: "Digite seu nome completo."
            },
            mensagem: {
                required: "Digite uma mensagem.",
                minlength: "A mensagem deve ter no minimo 20 caractéres.."
            },
            telefone: {
                required: "Digite um telefone.",
                minlength: "Digite telefone valido."
            },
            tipo: {
                required: ""
            },
            email: {
                required: "Digite um email.",
                email: "Digite e-mail valido."
            }
        }, submitHandler: function (form) {
            var dados = $(form).serialize();
            var host = THEMEPATH+"/inc/envia.php";

            var actBtn = $(form).find('button[type="submit"]');
            var oldText = actBtn.text();

            actBtn.text('Enviando...');
            actBtn.attr('disabled', true);

            $.ajax({
                type: "POST",
                url: host,
                async: true,
                data: dados,

                success: function (data) {
                    var result = data == '' ? data : JSON.parse(data);
                    if (result.status == '200') {
                        //window.location = "sucesso-fale-conosco.php";
                    } else {
                        actBtn.text(oldText);
                        actBtn.attr('disabled', false);
                    }
                },
                error: function (data) {
                    actBtn.text(oldText);
                    actBtn.attr('disabled', false);
                }
            });

            return false;
        }
    });
    $('#formContato').validate({
        rules: {
            nome: {
                required: true,
                minlength: 2
            },
            telefone: {
                required: true,
                minlength: 14
            },
            email: {
                required: true,
                email: true,
                testEmail: true
            },
            mensagem: {
                required: true,
                minlength: 14
            }
        },
        messages: {
            nome: {
                required: "Digite seu nome.",
                minlength: "Digite seu nome completo."
            },
            telefone: {
                required: "Digite um telefone.",
                minlength: "Digite telefone valido."
            },
            mensagem: {
                required: "Digite uma mensagem.",
                minlength: "A mensagem deve ter no minimo 20 caractéres.."
            },
            email: {
                required: "Digite um email.",
                email: "Digite e-mail valido."
            }
        },
        submitHandler: function (form) {
            var dados = $(form).serialize();
            var host = form.action;
            var actBtn = $(form).find('button[type="submit"]');
            var oldText = actBtn.text();

            actBtn.text('Enviando...');
            actBtn.attr('disabled', true);

            $.ajax({
                type: "POST",
                url: host,
                async: true,
                data: dados,

                success: function (data) {
                    var result = data == '' ? data : data;
                    if (result.status == '200') {
                        window.location = "/sucesso/contato/";
                    }
                    else {
                        actBtn.text(oldText);
                        actBtn.attr('disabled', false);
                    }
                },
                error: function (data) {
                    actBtn.text(oldText);
                    actBtn.attr('disabled', false);
                }
            });

            return false;
        }
    });
    $('#formFooter').validate({
        rules: {
            nome: {
                required: true,
                minlength: 2
            },
            telefone: {
                required: true,
                minlength: 14
            },
            email: {
                required: true,
                email: true,
                testEmail: true
            },
            mensagem: {
                required: true,
                minlength: 14
            }
        },
        messages: {
            nome: {
                required: "Digite seu nome.",
                minlength: "Digite seu nome completo."
            },
            telefone: {
                required: "Digite um telefone.",
                minlength: "Digite telefone valido."
            },
            mensagem: {
                required: "Digite uma mensagem.",
                minlength: "A mensagem deve ter no minimo 20 caractéres.."
            },
            email: {
                required: "Digite um email.",
                email: "Digite e-mail valido."
            }
        },
        submitHandler: function (form) {
            var dados = $(form).serialize();
            var host = form.action;
            var actBtn = $(form).find('button[type="submit"]');
            var oldText = actBtn.text();

            actBtn.text('Enviando...');
            actBtn.attr('disabled', true);

            $.ajax({
                type: "POST",
                url: host,
                async: true,
                data: dados,

                success: function (data) {
                    var result = data == '' ? data : data;
                    if (result.status == '200') {
                        window.location = "/sucesso/rodape/";
                    } else {
                        actBtn.text(oldText);
                        actBtn.attr('disabled', false);
                    }
                },
                error: function (data) {
                    actBtn.text(oldText);
                    actBtn.attr('disabled', false);
                }
            });

            return false;
        }
    });
    $('#formInterna').validate({
        rules: {
            nome: {
                required: true,
                minlength: 2
            },
            telefone: {
                required: true,
                minlength: 14
            },
            email: {
                required: true,
                email: true,
                testEmail: true
            },
            mensagem: {
                required: true,
                minlength: 14
            }
        },
        messages: {
            nome: {
                required: "Digite seu nome.",
                minlength: "Digite seu nome completo."
            },
            telefone: {
                required: "Digite um telefone.",
                minlength: "Digite telefone valido."
            },
            mensagem: {
                required: "Digite uma mensagem.",
                minlength: "A mensagem deve ter no minimo 20 caractéres.."
            },
            email: {
                required: "Digite um email.",
                email: "Digite e-mail valido."
            }
        },
        submitHandler: function (form) {
            var dados = $(form).serialize();
            var actBtn = $(form).find('button[type="submit"]');
            var oldText = actBtn.text();

            actBtn.text('Enviando...');
            actBtn.attr('disabled', true);

            $.ajax({
                type: "POST",
                url: form.action,
                async: true,
                data: dados,

                success: function (data) {
                    var result = data == '' ? data : data;

                    if (result.status == '200') {
                        window.location = "/sucesso/interna/";
                    } else {
                        actBtn.text(oldText);
                        actBtn.attr('disabled', false);
                    }
                },
                error: function (data) {
                    actBtn.text(oldText);
                    actBtn.attr('disabled', false);
                }
            });

            return false;
        }
    });
});
